@import url(https://fonts.googleapis.com/css?family=Raleway:400, 700, 800);
*,
*::before,
*::after {
  /* -webkit-box-sizing: border-box; */
  box-sizing: border-box;
}

html, body, #root, #App {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  width: 100%;
  margin-left: 0px;
  overflow: hidden;  
}


a {
  color: #4e4a46;
  text-decoration: none;
}

a:hover, a:focus {
  color: #c94e50;
}

main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
}

h1 {
  font-weight: 800;
  font-size: 3.75em;
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }
  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 32px;
  top: 36px;
}

._2NZr1  {
  min-width: 160px !important;
  width: 160px;
}

._2NZr1 ._2an5a {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px !important;
}

.bm-burger-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 8px;
}

.bm-burger-button button:focus+span span.bm-burger-bars {
  background-color: #c94e50;
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu {
  background: #373a47;
}

.bm-menu a {
  color: #b8b7ad;
  display: block;
}

.bm-menu a:hover, .bm-menu a:focus {
  color: #c94e50;
}

.bm-item-list a {
  padding: 0.8em;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

.bm-item:focus {
  outline: none;
}

.menu-1 .bm-cross {
  background: #bdc3c7;
}

.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.menu-1 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-1 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-2 .bm-cross {
  background: #999;
}

.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}

.menu-2 a {
  padding: 1em;
}

.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}

.menu-2 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-2 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-3 .bm-cross {
  background: #888;
}

.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}

.menu-3 i {
  opacity: 0.5;
}

.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}

.menu-3 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-3 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-4 .bm-cross {
  background: #888;
}

.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.menu-4 h2 i {
  margin-left: 0.2em;
}

.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}

.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}

.menu-4 a:hover, .menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

.menu-4 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-4 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#slide .bm-cross, #stack .bm-cross, #reveal .bm-cross {
  background: #bdc3c7;
}

#slide .bm-menu, #stack .bm-menu, #reveal .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

#slide .bm-cross-button button:focus, #stack .bm-cross-button button:focus, #reveal .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#slide .bm-cross-button button:focus+span span.bm-cross, #stack .bm-cross-button button:focus+span span.bm-cross, #reveal .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#bubble .bm-cross, #push .bm-cross {
  background: #999;
}

#bubble .bm-menu, #push .bm-menu {
  padding: 3.4em 1em 0;
}

#bubble a, #push a {
  padding: 1em;
}

#bubble i, #push i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}

#bubble .bm-cross-button button:focus, #push .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#bubble .bm-cross-button button:focus+span span.bm-cross, #push .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#elastic .bm-cross {
  background: #888;
}

#elastic .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}

#elastic i {
  opacity: 0.5;
}

#elastic span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}

#elastic .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#elastic .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#scaleDown .bm-cross, #scaleRotate .bm-cross, #pushRotate .bm-cross, #fallDown .bm-cross {
  background: #888;
}

#scaleDown h2, #scaleRotate h2, #pushRotate h2, #fallDown h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#scaleDown h2 i, #scaleRotate h2 i, #pushRotate h2 i, #fallDown h2 i {
  margin-left: 0.2em;
}

#scaleDown h2 span, #scaleRotate h2 span, #pushRotate h2 span, #fallDown h2 span {
  font-size: 1.6em;
  font-weight: 700;
}

#scaleDown a, #scaleRotate a, #pushRotate a, #fallDown a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#scaleDown a span, #scaleRotate a span, #pushRotate a span, #fallDown a span {
  letter-spacing: 1px;
  font-weight: 400;
}

#scaleDown a:hover, #scaleRotate a:hover, #pushRotate a:hover, #fallDown a:hover, #scaleDown a:focus, #scaleRotate a:focus, #pushRotate a:focus, #fallDown a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

#scaleDown .bm-cross-button button:focus, #scaleRotate .bm-cross-button button:focus, #pushRotate .bm-cross-button button:focus, #fallDown .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#scaleDown .bm-cross-button button:focus+span span.bm-cross, #scaleRotate .bm-cross-button button:focus+span span.bm-cross, #pushRotate .bm-cross-button button:focus+span span.bm-cross, #fallDown .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}